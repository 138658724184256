<template>
  <section id="search-results-container">
    <ul
      :class="{
        active: searchQuery.length >= 1,
      }"
    >
      <li v-if="loading" class="result-card"><span>Loading ...</span></li>

      <template
        v-else-if="!results.length && searchQuery.length >= 1 && !loading"
      >
        <li class="result-card inactive"><span>No results</span></li>
      </template>

      <template v-else>
        <SearchResultsItem
          v-for="dealer in results"
          :key="dealer.id + '-dealer-result'"
          :dealer="dealer"
          :selectedDealers="selectedDealers"
          v-on="{ onSelectecDealer: emitSelectedDealer }"
        />
      </template>
    </ul>
  </section>
</template>

<script>
import SearchResultsItem from "@/components/Invitation/SearchResultsItem.vue";
export default {
  name: "SearchResults",
  components: { SearchResultsItem },
  props: {
    searchQuery: {
      type: String,
    },
    results: {
      type: Array,
    },
    selectedDealers: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },

  methods: {
    emitSelectedDealer(dealer) {
      this.$emit("onSelectecDealer", dealer);
    },
  },
};
</script>

<style lang="scss">
#search-results-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    width: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s;
    border-radius: 5px;
    border: none;
    overflow-y: auto;
    box-shadow: 0px 9px 5px -3px #00000087;

    &.active {
      transform: scale(1);
    }

    @media screen and (max-width: 768px) {
      max-height: 350px;
    }
  }

  .result-card {
    display: flex;
    align-items: center;
    padding: 0.6rem 1rem;
    background-color: white;
    cursor: pointer;
    text-align: center;
    transition: all 0.15s;

    &:nth-child(odd) {
      background: #f2f2f2;
    }

    &:not(.inactive):hover {
      background-color: rgb(19, 19, 19);
      p {
        color: white;
      }
    }

    p {
      margin: 0;
    }

    p:first-child {
      font-weight: bold;
      margin-right: 1rem;
    }

    &.inactive {
      background: rgb(187, 187, 187);
      box-shadow: initial;
      cursor: not-allowed;
      border-radius: 5px;
      border: none;
      /* overflow-y: auto; */
      box-shadow: 0px 9px 5px -3px #00000087;

      p {
        color: white;
      }

      p:last-child {
        font-size: 0.7rem;
        margin-left: auto;
      }
    }
  }
}
</style>