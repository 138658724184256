<template>
  <section id="container">
    <section id="success" v-if="success">
      <p>Your invitation was sent!</p>
      <button class="delete-btn" @click="closeSuccessMessage">
        <img src="/images/cross-icon-white.png" alt="detele message button" />
      </button>
    </section>

    <section id="inner" v-if="!loading">
      <p class="header-grey">
        Invite one of your Dealers to get started using Gameplan. We will send
        them an email on your behalf, including all necessary infomation to get
        started with Gameplan.
        <a
          target="_blank"
          href="https://rhino2.dev2.axlot.com/preview-invitation"
          >PREVIEW THIS EMAIL HERE</a
        >
      </p>

      <Search
        v-on="{
          onSelectecDealer: handleSelectedDealer,
          onDeleteDealer: deleteDealer,
        }"
        :selectedDealer="selectedDealer"
      />

      <section id="email-container" v-if="showForm">
        <!-- Send To -->
        <article class="email-row">
          <p class="left-label">Send to <sup class="required">*</sup></p>
          <ul>
            <li v-for="(email, i) in manualEmailsTo" :key="i + '-in-email-to'">
              <p>{{ email }}</p>
              <button class="delete-btn" @click="deleteEmailTo(email)">
                <img src="/images/cross-icon.png" :alt="'detele ' + email" />
              </button>
            </li>

            <form @submit.prevent="handleAddNewEmailTo">
              <input
                type="email"
                v-model="emailToInput"
                @blur.stop="clearEmailToInput"
                name="clearEmailToInput"
                id="clearEmailToInput"
              />
            </form>
          </ul>
          <p class="label-bottom" v-if="!dealerIsEmptyError">
            Enter one or many contacts you want to receive this email.
          </p>
          <p v-if="dealerIsEmptyError" class="label-bottom error">
            You have to enter at least one email to send this email
          </p>
        </article>

        <!-- CC -->
        <article class="email-row">
          <p class="left-label">CC</p>
          <ul>
            <li
              v-for="(email, i) in manualEmailsWithCC"
              :key="i + '-in-email-CC'"
            >
              <p>{{ email }}</p>
              <button
                v-if="i !== 0"
                class="delete-btn"
                @click="deleteEmailCC(email)"
              >
                <img src="/images/cross-icon.png" :alt="'detele ' + email" />
              </button>
            </li>
            <form @submit.prevent="handleAddNewEmailForCC">
              <input
                type="email"
                v-model="emailCCInput"
                @blur.stop="clearEmailCCInput"
                name="clearEmailCCInput"
                id="clearEmailCCInput"
              />
            </form>
          </ul>
          <p class="label-bottom">
            Use this field to incude any FSG Contacts you want to be involved in
            this email thread.
          </p>
        </article>

        <!-- Message -->
        <article class="email-row">
          <p class="left-label">Message</p>
          <textarea
            v-model="emailMessage"
            :rows="10"
            :cols="50"
            :placeholder="textareaPlaceholder"
          />
        </article>

        <button @click="submitEmail">
          <span>SEND MESSAGE</span>
        </button>
      </section>
    </section>

    <section id="inner" class="loader-container" v-else>
      <Loader />
    </section>
  </section>
</template>

<script>
import Search from "@/components/Invitation/Search.vue";
import { mapState } from "vuex";
import axios from "axios";
import Loader from "../components/Utils/Loader.vue";
// import toastr from "toastr";

export default {
  name: "Invitation",
  components: { Search, Loader },

  data() {
    return {
      success: false,
      loading: false,
      dealerIsEmptyError: false,
      selectedDealer: {},
      manualEmailsTo: [],
      manualEmailsWithCC: ["gameplan@foundersportgroup.com"],
      emailToInput: "",
      emailCCInput: "",
      emailMessage: "",
      textareaPlaceholder: `Add a personal touch by writing a custom message to be included alongside our generic onboarding email.For example: let's set up a call to discuss this new suite of marketing tools, i believe they will ve a good fit for you and the team`,
    };
  },

  computed: {
    ...mapState(["endpoint", "token", "rep"]),

    formIsValid() {
      return this.manualEmailsTo.length > 0;
    },

    showForm() {
      return !!this.selectedDealer.email;
    },
  },

  methods: {
    checkForm() {
      this.dealerIsEmptyError = !this.formIsValid;
    },

    async submitEmail() {
      this.checkForm();

      if (this.formIsValid) {
        const url = this.endpoint + "/invite?api_token=" + this.token;

        const params = {
          to: this.manualEmailsTo,
          cc: this.manualEmailsWithCC,
          message: this.emailMessage,
          dealer_id: this.selectedDealer.id,
        };

        try {
          // this.$toastr.success("SUCCESS MESSAGE", "Success Toast Title");
          this.loading = true;
          await axios.post(url, params);
          // Send message to browser screen
          this.$toastr.s("Email successfully sent");
          this.clearFields();
          this.loading = false;
          this.success = true;
        } catch (error) {
          this.$toastr.e("There was an error");
          this.loading = false;
        }
      }
    },

    handleAddNewEmailTo() {
      const newEmail = this.emailToInput;
      this.manualEmailsTo.push(newEmail);

      //Reset email input
      this.emailToInput = "";
    },

    handleAddNewEmailForCC() {
      const newEmail = this.emailCCInput;
      this.manualEmailsWithCC.push(newEmail);

      //Reset email input
      this.emailCCInput = "";
    },

    deleteEmailTo(emailToDelete) {
      this.manualEmailsTo = this.manualEmailsTo.filter(
        (emailInArray) => emailInArray != emailToDelete
      );
    },

    deleteEmailCC(emailToDelete) {
      this.manualEmailsWithCC = this.manualEmailsWithCC.filter(
        (emailInArray) => emailInArray != emailToDelete
      );
    },

    handleSelectedDealer(dealer) {
      this.selectedDealer = dealer;
      this.manualEmailsTo.push(dealer.email);
    },

    deleteDealer() {
      this.selectedDealer = {};
    },

    clearEmailToInput() {
      this.emailToInput = "";
    },

    clearEmailCCInput() {
      this.emailCCInput = "";
    },

    clearAllEmail() {
      this.manualEmailsTo = [];
      this.manualEmailsWithCC = ["gameplan@foundersportgroup.com"];
    },

    clearFields() {
      this.clearEmailToInput();
      this.clearEmailCCInput();
      this.clearAllEmail();
      this.deleteDealer();
      this.manualEmailsWithCC = ["gameplan@foundersportgroup.com"];
      this.emailMessage = "";
    },

    closeSuccessMessage() {
      this.success = false;
    },
  },

  mounted() {
    // You are able to access plugin from everywhere via this.$toastr
    this.$toastr.defaultPosition = "toast-bottom-center";
  },

  watch: {
    formIsValid() {
      if (this.dealerIsEmptyError) this.checkForm();
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  height: 100%;
  background: white;

  .header-grey {
    color: grey;
    text-align: center;
    font-size: 1.1rem;
    a {
      color: grey;
      text-decoration: underline;
    }
  }

  #success {
    padding: 1rem;
    background: #14b311;
    width: 75%;
    margin: auto;
    margin-top: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    p {
      margin: auto;
      margin: auto;
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
    }

    button {
      padding: 0.5rem;
      background: black;
      position: absolute;
      right: 10px;
      border: none;
      border-radius: 3px;
      margin: auto;
      margin-left: auto !important;
      span {
        color: white;
        color: white;
        font-weight: bold;
        font-family: "ForzaBold";
      }
    }
  }

  #inner {
    width: 60%;
    max-width: 720px;
    margin: auto;
    padding-top: 5rem;

    &.loader-container {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      width: 95%;
    }
  }

  #email-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;

    & > * {
      margin-bottom: 2rem;
    }

    .email-row {
      display: flex;
      justify-content: center;
      position: relative;
      flex-wrap: wrap;

      .left-label {
        position: absolute;
        left: -55px;
        font-weight: bold;
      }

      .label-bottom {
        width: 100%;

        &.error {
          background: red;
          color: white;
          padding: 0.3rem;
          border-radius: 5px;
        }
      }

      ul,
      & > input,
      & > textarea {
        width: 100%;
        background: white;
        border: 1px solid black;
        border-radius: 3px;
      }
      textarea {
        /* min-height: 150px; */
        padding: 1rem;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        padding: 0.3rem 0.5rem;
        align-items: center;
        gap: 0.5rem;

        li {
          background: #e3e3e3;
          border-radius: 4px;
          display: flex;
          align-items: center;
          margin-right: 1rem;
          padding: 0.2rem;
          list-style: none;
          text-align: center;
          width: auto;
          p {
            margin: 0;
            margin-right: 0.5rem;
            padding: 0.5rem;
          }
          .delete-btn {
            /* width: 8px; */
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            padding: 0.5rem;
            span {
              font-size: 10px;
              color: red;
            }
          }
        }

        input {
          width: 100%;
          border: none !important;
          padding: 0.5rem;
        }

        form {
          border: none !important;
          flex-grow: 1;
          input:focus {
            border: none !important;
          }
        }
      }
    }

    button {
      padding: 1rem;
      background: black;

      border: none;
      border-radius: 3px;
      margin: auto;
      width: 50%;
      span {
        color: white;
        color: white;
        font-weight: bold;
        font-family: "ForzaBold";
      }
    }

    @media (max-width: 768px) {
      width: 75%;

      .email-row {
        flex-direction: column;
        .left-label {
          position: initial;
        }
      }
    }

    @media (max-width: 576px) {
      width: 95%;
    }
  }
}
</style>