<template>
  <div class="wrapper">
    <li
      class="result-card"
      @click="emitSelectedDealer(dealer)"
      :class="{ inactive: alreadySelected }"
    >
      <p>{{ dealer.account_number }}</p>
      <p>{{ dealer.name }}</p>
      <p v-if="alreadySelected">CURRENTLY ACTIVE</p>
      <!-- :class="{ inactive: d(dealer.id) }" -->
    </li>
    <button
      v-if="alreadySelected"
      class="delete-dealer"
      @click="emitDeleteDealer"
    >
      <span>X</span>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchResultsItem",

  props: {
    dealer: {
      type: Object,
    },
    selectedDealers: {
      type: Array,
    },
    alreadySelected: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState(["endpoint"]),
  },

  methods: {
    emitSelectedDealer(dealer) {
      !this.alreadySelected && this.$emit("onSelectecDealer", dealer);
    },
    emitDeleteDealer() {
      this.$emit("onDeleteDealer");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    width: 100%;
  }

  li.inactive {
    width: 80%;
  }

  button.delete-dealer {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}
</style>
