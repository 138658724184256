<template>
  <!-- Search products -->
  <section id="search-products-container">
    <template v-if="showSearchBar">
      <SearchBar :searchQuery="searchQuery" @input="handleInputChange" />
      <SearchResults
        :searchQuery="searchQuery"
        :results="results"
        :loading="loading"
        :selectedDealer="selectedDealer"
        v-on="{ onSelectecDealer: emitSelectedDealer }"
      />
    </template>

    <template v-else>
      <section id="search-results-container">
        <SearchResultsItem
          :dealer="selectedDealer"
          :selectedDealer="selectedDealer"
          :alreadySelected="!showSearchBar"
          v-on="{ onDeleteDealer: emitDeleteDealer}"
          
        />
      </section>
    </template>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import _ from "lodash";

import SearchBar from "@/components/Invitation/SearchBar.vue";
import SearchResults from "@/components/Invitation/SearchResults.vue";
import SearchResultsItem from "@/components/Invitation/SearchResultsItem.vue";
export default {
  name: "Search",
  components: { SearchBar, SearchResults, SearchResultsItem },

  props: ["selectedDealer"],

  data() {
    return {
      searchQuery: "",
      results: [],
      loading: false,
      error: false,
    };
  },

  computed: {
    ...mapState(["endpoint", "token"]),

    showSearchBar() {
      return !this.selectedDealer.email;
    },
  },

  methods: {
    ...mapActions("snapsheet", ["changeEditMode"]),

    handleInputChange(value) {
      this.searchQuery = value;
    },

    getDealersByQuery: _.debounce(async function () {
      if (this.searchQuery.length >= 1) {
        this.loading = true;

        try {
          const { data } = await axios.get(this.endpoint + "/search", {
            params: {
              search_query: this.searchQuery,
              api_token: this.token,
            },
          });

          this.results = data.data;
        } catch (error) {
          alert("error");
          this.error = true;
        } finally {
          this.loading = false;
        }
      } else if (this.searchQuery.length == 0) {
        this.results = [];
      }
    }, 500),

    emitSelectedDealer(dealer) {
      this.searchQuery = "";
      this.results = [];
      this.$emit("onSelectecDealer", dealer);
    },

    emitDeleteDealer(){
      this.$emit("onDeleteDealer");
    }
  },
  watch: {
    searchQuery() {
      this.getDealersByQuery();
    },
  },
};
</script>

<style lang="scss">
#search-products-container {
  overflow-y: visible;
  width: 75%;
  margin: auto;
  margin-bottom: 2rem;

  .close-card-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.4rem;
    button {
      height: 30px;
      width: 30px;
      background: rgb(12, 12, 12);
      border: none;
      color: white;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  button.delete-dealer {
    padding: 1rem;
    background: black;
    border-radius: 5px;
    span {
      color: white;
    }
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    width: 90% !important;
  }

  @media screen and (max-width: 576px) {
    padding: 0.5rem;
  }
}
</style>