<template>
  <article id="search-bar-container">
    <h2>Select a Dealer</h2>
    <label for="search-input">
      <figure>
        <img src="/images/loupe-icon.png" alt="search icon" />
      </figure>
      <input
        id="search-input"
        name="search-input"
        type="text"
        :value="productQuery"
        @input="$emit('input', $event.target.value)"
        ref="inputField"
        autocomplete='off'
      />
    </label>
  </article>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    productQuery: {
      type: String,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputField.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
#search-bar-container {
  position: sticky;
  top: 0;
  z-index: 10;
  color: whitesmoke;
  margin-bottom: 0.5rem;
  padding: 1rem;

  h2 {
    font-family: "SlateStd";
    text-transform: uppercase;
    color: black;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }

  label {
    display: flex;
    /* border: 1px solid grey; */
    position: relative;
    padding: 0;
    width: 100%;
    border-radius: 6px;

    figure {
      position: absolute;
      height: 100%;
      display: flex;
      justify-content: center;
      top: 0;
      width: 3.2rem;
      align-items: center;
      background: #f3f3f3;
      border: 1px solid grey;
      border-radius: 0 6px 6px 0;
      border-radius: 6px;
      img {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    input {
      border: none;
      width: 100%;
      height: calc(1.5em + 1.3rem + 2px);
      padding: 0.25rem 1rem;
      padding-left: 5rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      border-radius: 6px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid grey;
      border-radius: 6px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid black;
      }
    }
  }
}
</style>