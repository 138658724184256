import { render, staticRenderFns } from "./SearchResultsItem.vue?vue&type=template&id=6d2fb1bf&scoped=true&"
import script from "./SearchResultsItem.vue?vue&type=script&lang=js&"
export * from "./SearchResultsItem.vue?vue&type=script&lang=js&"
import style0 from "./SearchResultsItem.vue?vue&type=style&index=0&id=6d2fb1bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d2fb1bf",
  null
  
)

export default component.exports